
@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.data-table {
  // font-size: 14px
  &-loading {
    align-items: center;
    display: flex;
    height: 200px;
    flex-flow: column;
    justify-content: center;
    position: relative;
    width: 100%;
    &-spinner {
      animation: spin 1s linear infinite;
      border-radius: 999px;
      border: 2px solid #007bff;
      border-top-color: transparent;
      content: '';
      height: 75px;
      margin-bottom: 15px;
      width: 75px; }
    &-text {
      font-weight: 300;
      text-trnasform: uppercase; } }

  &-control {
    .custom-select {
      width: initial; } }

  .table {
    &-responsive {
      min-height: 100px;
      // margin-bottom: 30px
      // &::-webkit-scrollbar
      //  -webkit-apperance: none
      //  height: 15px
      //  width: 15px
      //  &-track
      //    background: #eee
      //    border-radius: 999px
      //  &-thumb
      //    background: #ccc
      //    border-radius: 999px
      //    border: 3px solid #eee
      //    &:focus
 }      //      background: #ccc
    &.straight {
      white-space: nowrap; }
    thead {
      th {
        opacity: .5;
        &.sortable {
          cursor: pointer;
          .sort-icon {
            height: 10px;
            margin-left: 5px;
            // padding-right: 30px
            position: relative;
            width: 4px;
            &:before,
            &:after {
              border: 1.8px solid transparent;
              content: '';
              display: block;
              left: 0;
              opacity: .3;
              position: absolute;
 }              // right: 10px
            &:before {
              border-bottom-color: currentColor;
              top: 0px;
              border-bottom-width: 3px; }
            &:after {
              bottom: 0px;
              border-top-color: currentColor;
              border-top-width: 3px; } } }
        &.sort {
          font-weight: 700;
          opacity: 1;
          &.asc {
            .sort-icon {
              &:before {
                opacity: 1; } } }
          &.desc {
            .sort-icon {
              &:after {
                opacity: 1; } } } } } }
    tbody {
      tr {
        &.clickable {
          cursor: pointer; } }
      td {
 } }        // font-size: 12px

    &-filters {
      margin-bottom: 15px; }

    &-filter {
      background: #fff;
      border-radius: 3px;
      cursor: pointer;
      color: #777;
      display: inline-block;
      font-size: 12px;
      padding: 5px 15px;
      margin: 0 0 3px 3px;
      &:hover {
        background: #aaa;
        color: #fff; }
      &.active {
        background: #337ab7;
        color: #fff; } } } }

.filter-class {
  @apply border border-gray-500 cursor-pointer font-bold mb-2 mr-2 px-3 py-2 rounded-sm text-11 text-gray-500 uppercase {}
  &:hover {
    @apply border-blue-500 text-blue-500 {} }
  &.active {
    @apply bg-blue-500 border-blue-500 text-white {} } }
